'use client';
import React from 'react';
import Header from '../../../components/Header';
import { ideaClipsImage } from '@/config/img/ideaclips';
import Image from 'next/image';
import { imageLoader } from '@/utils';

export default function HomeHeader() {
  return (
    <header className='flex justify-center w-full h-[608px] bg-[#E2F4FE] max-md:hidden'>
      <div className="wrap relative w-[1580px]">
      <Header page="home" />
      <Image alt='home' loader={imageLoader} src={ideaClipsImage.HeaderImage} width={973} height={608} className='absolute top-0 right-[125px] -z-0' />
      <div className='w-full h-[528px] flex flex-col px-[150px] justify-center'>
        <h1 className='w-[600px] font-bold text-[50px] leading-[68px]'>{'Breve resumo dos melhores livros do mundo'}</h1>
        <h2 className='w-[600px] pt-3 text-[20px] leading-[30px]'>Destilamos meticulosamente os principais insights de cada livro em cartões de conhecimento concisos, com o objetivo de fornecer aos leitores uma maneira conveniente e eficiente de entender a essência de cada livro em apenas 3 minutos.</h2>
      </div>
      </div>
    </header>
  );
}
