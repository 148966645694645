'use client';
import React from 'react';

interface IProps {
  category: string[];
}


export default function Category(props: IProps) {
  console.log(props);
  return (
    <div className='pt-[60px]'>
      <h2 className='text-center text-[36px] leading-[49px] font-bold'>CATEGORIES</h2>
      <div >

      </div>
    </div>
  );
}
